import { useState, useContext, ChangeEvent, useEffect, ReactNode } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { TextField, MenuItem, Button, Switch } from '@mui/material';
import { Card, Column, Row, Title, SelectField, DragCard } from "../Course/components/style";
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TrainingService from "../../../shared/services/api/trainingService";
import { getUploadUrl, handleUpload } from "../../../shared/services/api/S3Service";
import AuthContext from "../../../shared/context/AuthContext";
import ContentUploader from "./LessonContentUploader";
import { BackButton, BtnCreate, BtnEdit, CreateButton, CreateCourseButton } from "../style";
import TimedAlert from "./TimedAlert";
import BackdropTimedAlert from "./BackdropTimedAlert";
import { LearnEntityType, LessonDetailsFormI } from "./lessonInterfaces";
import ModalSetLessons from "./ModalSetLessons";
import CardUpload from "../../../shared/components/Cards/CardUpload";
import { LabelUpload } from "../../../shared/components/Cards/style";
import { handleFileChange } from "../../../shared/enums/UploadFile";
import { Label } from "@mui/icons-material";



/**
 * Se o curso existir previamente, o componente deve buscar as informações do curso para edição.
 * Se o curso não existir previamente, o componente deve criar.
 * Se receber um id de trilha ou treinamento, o componente deve vincular o curso com o treinamento ou trilha.
 */
interface CourseFromProps {
    title: string;
    placeholder: string;
    value: any;
    onChange: any;
};

interface SelectFormProps {
    title: string;
    label: string;
    value: any;
    children: ReactNode;
    onChange: any;
};

const CardSelect = ({ title, label, value, children, onChange }: SelectFormProps) => {
    return (
        <Card>
            <Row>
                <Title>
                    {title}
                </Title>
            </Row>
            <Column>
                <TextField
                    variant='outlined'
                    select
                    label='Permissionamento *'
                    required
                    style={{
                        marginTop: '6px',
                        marginLeft: '6px',
                        marginRight: '6px',
                        marginBottom: '6px',
                    }}
                    onChange={onChange}
                    value={value}
                >
                    <MenuItem value={"ALL"} >Todos</MenuItem>
                    <MenuItem value={"AGENT"} defaultChecked>Agente</MenuItem>
                    <MenuItem value={"LEADERSHIP"}>Liderança</MenuItem>
                </TextField>
            </Column>
        </Card>
    )
}

const CardCourse = ({ title, placeholder, value, onChange }: CourseFromProps) => {
    return (
        <Card>
            <Row>
                <Title>
                    {title}
                </Title>
            </Row>
            <Column>
                <TextField
                    placeholder={placeholder}
                    style={{
                        marginTop: '6px',
                        marginLeft: '6px',
                        marginRight: '6px',
                        marginBottom: '6px',
                    }}
                    onChange={onChange}
                    value={value}
                />
            </Column>
        </Card>
    )
}


const LessonDetailsForm = (props: LessonDetailsFormI) => {
    /**
     * Actions: 
     *  - changePermission
     *  - changeTitle
     *  - changeSubtitle
     *  - changeDescription
     *  - changeCategory
     *  - changeCoverFile
     *  - changeVideoFile
     *  - changeConentFile
     *  - changeKeyWords
     *  - changePublicationState
     */

    let { id = '', trainingId = '', trilhaId = '', type = '' } = { ...props }
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [description, setDescription] = useState('');
    const [permission, setPermission] = useState('');
    const [category, setCategory] = useState('');
    const [learnEntity, setLearnEntity] = useState<LearnEntity | null>(null);
    const [coverFile, setCoverFile] = useState('');
    const [contentFile, setContentFile] = useState('');
    const [videoFile, setVideoFile] = useState('');
    const [errors, setErrors] = useState<string[]>([]);
    const [success, setSuccess] = useState<string[]>([]);
    const [published, setPublished] = useState<boolean>(false);
    const [showInHome, setShowInHome] = useState<boolean>(false);
    const [showUnbound, setShowUnbound] = useState<boolean>(false);
    const [lessonId, setLessonId] = useState<string>('');
    const [showSetLessons, setShowSetLessons] = useState<boolean>(false);
    const [isYoutubeVideo, setIsYoutubeVideo] = useState<boolean>( false );

    const [url, setUrl] = useState<String>();

    const currentLocation = useLocation();
    const navigate = useNavigate();

    interface LearnEntity {
        _id?: string;
        title: string;
        subtitle: string;
        description: string;
        role: string;
        type: string;
        published: boolean;
        showInHome: boolean;
        showUnbound: boolean;
    }


    const {
        user,
        jwtUser,
        jwtToken,
        signIn,
        signOut,
        authLoading,
        authError,
    } = useContext(AuthContext)

    const formType = type === 'course' ?
        'Curso' :
        type === 'training' ?
            'Treinamento' :
            'Trilha';


    const createNewLessonEntity = async () => {

        const creationalObject: LearnEntity = {
            title,
            subtitle,
            description,
            role: permission,
            type: category,
            published,
            showUnbound,
            showInHome,
        };

        console.log("Form type: ", formType);
        console.log("Creational object: ", creationalObject);

        let createdCourseInfo;

        try {
            switch (formType) {
                case 'Curso':
                    if (lessonId.length > 0) {
                        createdCourseInfo = await TrainingService.updateLesson({ ...creationalObject, _id: lessonId });
                        setSuccess(prev => [...prev, 'Curso atualizado com sucesso!'])
                    } else {
                        createdCourseInfo = await TrainingService.createLesson(creationalObject);
                        setSuccess(prev => [...prev, 'Curso criado com sucesso!'])
                    }
                    break;
                case 'Treinamento':
                    if (lessonId.length > 0) {
                        createdCourseInfo = await TrainingService.updateTreinamento(lessonId, creationalObject);
                        setSuccess(prev => [...prev, 'Treinamento atualizado com sucesso!'])
                    } else {
                        createdCourseInfo = await TrainingService.createTreinamento(creationalObject);
                        setSuccess(prev => [...prev, 'Treinamento criado com sucesso!'])
                    }
                    break;
                case 'Trilha':
                    if (lessonId.length > 0) {
                        createdCourseInfo = await TrainingService.updateCollection(lessonId, creationalObject);
                        setSuccess(prev => [...prev, 'Trilha atualizada com sucesso!'])
                    } else {
                        createdCourseInfo = await TrainingService.createCollection(creationalObject);
                        if(creationalObject._id){
                            setSuccess(prev => [...prev, 'Trilha criada com sucesso!'])}
                    }
                    break;
                default:
                    break;

            }
            console.log(createdCourseInfo);
            setLearnEntity(createdCourseInfo);
            setLessonId(createdCourseInfo._id);
            setTitle(createdCourseInfo.title);
            setSubtitle(createdCourseInfo.subtitle);
            setDescription(createdCourseInfo.description);
            setPermission(createdCourseInfo.role);
            setCategory(createdCourseInfo.type);
            setLearnEntity(createdCourseInfo);
            setCoverFile(createdCourseInfo.cover);
            setContentFile(createdCourseInfo.extras);
            setVideoFile(createdCourseInfo.videoFile);
            setShowInHome(createdCourseInfo.showInHome);
            setShowUnbound(createdCourseInfo.showUnbound);
            setPublished(createdCourseInfo.published);
            setLessonId(createdCourseInfo._id);
        } catch (error: any) {
            alert(error.message);
        }

    }
    const editLessonEntity = (): void | never => {

    }

    const handleCreationEdition = async () => {
        createNewLessonEntity();
    }

    const delteLessonEntity = async () => {
        try {
            await TrainingService.deleteLearnEntity(learnEntity!._id);
            setSuccess(prev => [...prev, 'Curso excluído com sucesso!']);
            navigate(-1);
        } catch (err: any) {
            console.log(err)
            setErrors(prev => [...prev, err.message]);
        }
    }

    const updateLesson = async (updateInfo: Object) => {
        /**
         * Envia os parciais ou completos do objeto, ex:
         * {...learnEntity, title: 'Novo título' }
         * {...learnEntity, title: 'Novo título', subtitle: 'Novo subtítulo}
         */
        const newLearnEntity = { ...learnEntity };
        Object.assign(newLearnEntity, updateInfo);
        try {
            const updatedLesson = await TrainingService.updateLesson(newLearnEntity);
            setLearnEntity(updatedLesson);
        } catch (err: any) {
            console.log(err)
            alert(err.message)
        }
    }

    const updateCoverFileUrl = async (url: string) => {
        await updateLesson({ cover: url });
        setCoverFile(url);
    }

    const updateVideoFileUrl = async (url: string) => {
        await updateLesson({ videoFile: url });
        setVideoFile(url);
    }

    const updateMaterialComplementar = async (url: string) => {
        await updateLesson({ extras: url });
        setContentFile(url);
    }

    const addError = (err: string) => {
        setErrors(prev => [...prev, err]);
    }

    const removeError = (id: number) => {
        setErrors(prev => prev.filter((err, index) => index !== id));
    }

    const removeSuccess = (id: number) => {
        setSuccess(prev => prev.filter((suc, index) => index !== id))
    }

    useEffect(() => {
        async function loaderData() {
            if ( id || lessonId.length > 0) {
                const lesson = type === 'course' ? 
                    await TrainingService.getLesson( id ?? lessonId ) :
                    type === 'training' ?
                    await TrainingService.getTreinamentos( id ?? lessonId ) :
                    await TrainingService.getCollection( id?? lessonId ); 
                console.log("The lesson is: ", lesson);
                setTitle(lesson.title);
                setSubtitle(lesson.subtitle);
                setDescription(lesson.description);
                setPermission(lesson.role);
                setCategory(lesson.type);
                setLearnEntity(lesson);
                setCoverFile(lesson.cover);
                setContentFile(lesson.extras);
                setVideoFile(lesson.videoFile);
                setShowInHome(lesson.showInHome);
                setShowUnbound(lesson.showUnbound);
                setPublished(lesson.published);
                setLessonId(lesson._id);
            }
        }

        loaderData();
    }, [id])

    return (
        <>
            <Column>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <BackButton style={{ margin: '0px 0px 6px -18px' }} onClick={() => navigate(-1)}>
                        <ArrowBackIosIcon sx={{ margin: '0px -6px -6px 0px', }} />
                        Voltar
                    </BackButton>
                </div>

                <Row>
                    <DashboardRoundedIcon
                        sx={{
                            margin: '16px 8px 0px 10px',
                            color: '#1C6AB9'
                        }}
                    />
                    <h2>Customize {formType === 'Curso' ?
                        'seu Curso' :
                        formType === 'Treinamento' ?
                            'seu Treinamento' :
                            'sua Trilha'}
                    </h2>
                </Row>

                <CardCourse
                    title={`Título *`}
                    placeholder="Insira um titulo *"
                    value={title}
                    onChange={(e: any) => setTitle(e.target.value)}
                />
                <CardCourse
                    title={`Subtítulo *`}
                    placeholder="Insira um subtitulo *"
                    value={subtitle}
                    onChange={(e: any) => setSubtitle(e.target.value)}
                />
                <CardCourse
                    title={`Descrição *`}
                    placeholder="Insira uma descrição *"
                    value={description}
                    onChange={(e: any) => setDescription(e.target.value)}
                />

                <Card>
                    <Row>
                        <Title>
                            Permissionamento **
                        </Title>
                    </Row>
                    <Column>
                        <TextField
                            variant='outlined'
                            select
                            label='Permissionamento *'
                            color='secondary'
                            required
                            style={{
                                marginTop: '6px',
                                marginLeft: '6px',
                                marginRight: '6px',
                                marginBottom: '6px',
                            }}
                            onChange={(e) => setPermission(e.target.value)}
                            value={permission}
                        >
                            <MenuItem value={"ALL"} >Todos</MenuItem>
                            <MenuItem value={"AGENT"} defaultChecked>Agente</MenuItem>
                            <MenuItem value={"LEADERSHIP"}>Liderança</MenuItem>
                        </TextField>
                    </Column>
                </Card>
                <Card>
                    <Row>
                        <Title>
                            Tipo **
                        </Title>
                    </Row>
                    <Column>
                        <TextField
                            variant='outlined'
                            select
                            label='Tipo *'
                            color='secondary'
                            required
                            style={{
                                marginTop: '6px',
                                marginLeft: '6px',
                                marginRight: '6px',
                                marginBottom: '6px',
                            }}
                            onChange={(e) => setCategory(e.target.value)}
                            value={category}
                        >
                            <MenuItem value={"SYSTEM"} >Tecnologia</MenuItem>
                            <MenuItem value={"SOUKW"} defaultChecked>Institucional</MenuItem>
                            <MenuItem value={"CULTURE"} >Cultura</MenuItem>
                            <MenuItem value={"MODELS"} >Modelos</MenuItem>
                            {/* <MenuItem value={"KWW"}>KWW</MenuItem> */}
                        </TextField>
                    </Column>
                </Card>
                <Row>
                    <label>
                        <Switch checked={showInHome} onChange={(e) => { setShowInHome(prev => !prev) }} /> Aparecer no Front
                    </label>

                    <label>
                        <Switch checked={published} onChange={(e) => { setPublished(prev => !prev) }} /> Publicar
                    </label>

                    <label>
                        <Switch checked={showUnbound} onChange={(e) => { setShowUnbound(prev => !prev) }} /> Desacoplado
                    </label>
                </Row>

                <Row>
                    <BtnCreate onClick={() => { handleCreationEdition() }}>
                        {lessonId && lessonId.length > 0 ? 'Salvar Alterações' : 'Criar'}
                    </BtnCreate>
                    <BtnEdit onClick={() => { delteLessonEntity() }}>
                        {lessonId && lessonId.length > 0 ? 'Deletar' : 'Cancelar'}
                    </BtnEdit>
                </Row>
                {
                    success.length > 0 &&
                    success.map((suc, index) => <BackdropTimedAlert
                        timeout={5}
                        message={suc}
                        alertType="success"
                        errorId={index}
                        key={`${index}-${suc}`}
                        onClose={(index?: number) => {
                            if (index)
                                removeSuccess(index)
                        }}
                    />)
                }

            </Column>
            <div style={{ width: '12px' }} />
            <Column style={{ marginTop: '44px' }}>
            {learnEntity &&
                <div>
                    <Row>
                        <DashboardRoundedIcon
                            sx={{
                                margin: '16px 8px 0px 10px',
                                color: '#1C6AB9'
                            }}
                        />
                        <h2>Customize sua Aula</h2>
                    </Row>
                    <ContentUploader
                        type='image'
                        title='Selecione a Capa'
                        meaning='cover'
                        S3Directory={
                            // learnEntity._id
                            // ||
                            ''
                        }
                        errorHandler={(err: string) => { addError(err) }}
                        setUploadFileUrl={updateCoverFileUrl}
                        currentUrl={coverFile}
                    />
                    <div>
                    <p>Vídeo armazenado no: S3<Switch onChange={ ( ) => {
                        setIsYoutubeVideo( prev => { return !prev } );
                    }} />Youtube</p>
                    {!isYoutubeVideo ? <ContentUploader
                        type='video'
                        title='Selecione o Vídeo'
                        meaning='contentVideo'
                        S3Directory={
                            // learnEntity._id
                            // ||
                            ''
                        }
                        errorHandler={(err: string) => { addError(err) }}
                        setUploadFileUrl={updateVideoFileUrl}
                        currentUrl={videoFile}
                    /> : <ContentUploader
                        type='youtube'
                        title='Informe a url'
                        meaning='contentVideo'
                        S3Directory={
                            // learnEntity._id
                            // ||
                            ''
                        }
                        errorHandler={(err: string) => { addError(err) }}
                        setUploadFileUrl={updateVideoFileUrl}
                        currentUrl={videoFile}
                    />}
                    </div>

                    <ContentUploader
                        type='document'
                        title='Selecionar material complementar'
                        S3Directory={learnEntity._id || ''}
                        errorHandler={ ( err: string ) => { addError( err ) } }
                        meaning='contentDocument'
                        setUploadFileUrl={ updateMaterialComplementar } 
                        currentUrl={ contentFile }
                    />
                    { type !== 'course' && 
                        <Card>
                            <h3>Criar Vículos</h3>
                            <Button onClick={( ) => { setShowSetLessons( true ) }} >Configurar Relacionamentos</Button>
                            <ModalSetLessons 
                                isOpen={showSetLessons}
                                fClose={( ) => setShowSetLessons( false )}
                                lessonId={id ?? lessonId}
                                lessonsEntityType={type} 
                            />
                        </Card>
                    }
                </div>
                }
                {
                    errors.length > 0 &&
                    errors.map( (err, index) => <BackdropTimedAlert 
                        timeout={5} 
                        message={'Ocorreu um erro durante sua solicitação. Informe este erro ao suporte: ' + err}
                        alertType="error"
                        errorId={index}
                        key={`${index}-${err}`}
                        onClose={( index?: number ) => {
                            if( index )
                                removeError( index )
                        } }	
                        />)
                }
                          
            </Column>

        </>
    )
}

export default LessonDetailsForm;
