//export const BASE_URL = 'http://127.0.0.1:3000';
export const BASE_URL = 'https://qa.soukw.com.br:3000';
// export const BASE_URL = 'https://soukw.com.br:3000';
export const AUTH_ENDPOINT = `${BASE_URL}/auth`;
export const USERS_ENDPOINT = `${BASE_URL}/users`;
export const MCS_ENDPOINT = `${BASE_URL}/mcs`;
export const TEAMS_ENDPOINT = `${BASE_URL}/teams`;
export const LISTINGS_ENDPOINT = `${BASE_URL}/listingsCommand`;
export const LISTINGS_LEGADOS_ENDPOINT = `${BASE_URL}/preListings/my`;
//export const TRAINING_ENDPOINT = `${BASE_URL}/trilhas`;
// export const COURSE_ENDPOINT = `${BASE_URL}/treinamentos`;
export const UPDATE_ENDPOINT = `${BASE_URL}/updates`
export const TRAINING_ENDPOINT = `${BASE_URL}/ead/rails`;
export const COURSE_ENDPOINT = `${BASE_URL}/ead/trainings`;
export const LESSON_ENDPOINT = `${BASE_URL}/ead/courses`;
export const LEARN_ENTITY_ENDPOINT = `${BASE_URL}/ead/learnEntity`;
export const S3_ENDPOINT = `${BASE_URL}/ead`;
export const TRANSMITTAL_ENDPOINT = `${BASE_URL}/transmittal`;

