import { Divider, Grid } from "@mui/material";
import { Navbar, Sidebar } from "../../../shared/components";
import GridUsers from "../../../shared/components/Grids/GridUsers";
import MenuAdmin from "../../../shared/components/Menu/MenuAdmin";
import { ButtonUser, Column, Row, Title } from "../style";
import { Link } from "react-router-dom";
import { useState } from "react";

interface User {
    fullName: string;
    status: string;
    kwuid: number;
    marketCenterId: string;
    permissionLevel: string;
    teamId: string;
    updatedAt: string;
}

interface MC {
    _id: string;
    name: string;
    kwId: string;
    regionId: string;
    updatedAt: string;
}

const UsersPage = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [mcs, setMcs] = useState<MC[]>([]);
    return (
        <>
            <Navbar />
            <MenuAdmin />
            <Sidebar Children={
                <Column style={{ marginTop: '36px' }}>
                    <Row>
                        <Title>Usuários...</Title>
                        <ButtonUser>Cadastrar Usuário+</ButtonUser>
                    </Row>
                    <GridUsers children={
                        users.map(user => {
                            return (
                                <Grid item xs={12}>
                                    <Divider style={{ width: '100%' }} />
                                    <div style={{ width: '100%', padding: '5px' }}>
                                        <Link to={'/listings/edit-mc/'} style={{ textDecoration: 'none', color: '#565e70' }}>
                                            <Grid container
                                                justifyItems='flex-start'
                                                textAlign={'start'}
                                                style={{
                                                    cursor: 'pointer', paddingTop: '8px', paddingBottom: '8px'
                                                }}
                                            >
                                                <Grid item lg={2.5} xs={2.5}>
                                                    {user.fullName}
                                                </Grid>
                                                <Grid item lg={1.5} xs={1.5}>
                                                    {user.status}
                                                </Grid>
                                                <Grid item lg={1.6} xs={1.6}>
                                                    {user.kwuid}
                                                </Grid>
                                                <Grid item lg={1.5} xs={1.5}>
                                                    {user.permissionLevel}
                                                </Grid>
                                                <Grid item lg={1.4} xs={1.4}>
                                                    {mcs?.find(mc => mc._id === user.marketCenterId)?.name}
                                                </Grid>
                                                <Grid item lg={1.4} xs={1.4}>
                                                    {mcs?.find(mc => mc._id === user.teamId)?.name}
                                                </Grid>
                                                <Grid item lg={2} xs={2}>
                                                    {new Date(user.updatedAt).toLocaleDateString('pt-br')}
                                                </Grid>
                                            </Grid>
                                        </Link>
                                    </div>
                                    <Divider style={{ width: '100%' }} />
                                </Grid >)
                        })
                    } />
                </Column>
            } />
        </>
    )
}

export default UsersPage;