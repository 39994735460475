import React, { useContext, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Grid, Tooltip } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import AuthContext from '../../context/AuthContext';

interface SidebarProps {
    Children: React.ReactNode;

};

interface partnerProps {
    title: string;
    pageTitle: string;
    imgSrc: string;
    link: string;
}

interface soukwProps {
    title: string;
    pageTitle: string;
    imgSrc: string;
    route: string;
    selected?: string;
    className: string;
    classNameIcon: string;
    classNameText: string;
}


const Sidebar: React.FC<SidebarProps> = (props) => {
    const location = useLocation();
    const [role, setRole] = useState(false);
    const { jwtUser, user } = useContext(AuthContext)

    useEffect(() => {
        if (user?.permissionLevel === "ADMIN") {
            setRole(true)
        }
    }, [role])



    const GoToPartner: React.FC<partnerProps> = (props) => {
        return (
            <Tooltip title={props.pageTitle} placement='left'>
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '1.2rem' }}>
                    <a style={{}} href={props.link} target='_blank'>
                        <Grid container spacing={0} className={'sidebar-box'}>
                            <img src={props.imgSrc} className='sidebar-icon-soukw' />
                        </Grid>
                    </a>
                    <p className='sidebar-text-soukw'>{props.title}</p>
                </div>
            </Tooltip>

        )
    }
    const GoToSouKw: React.FC<soukwProps> = (props) => {
        return (
            <Tooltip title={props.pageTitle} placement='left'>
                <Link to={props.route}>
                    <div className={props.selected} style={{ display: 'flex', flexDirection: 'row' }}>
                        <Grid style={{ marginLeft: '1.2rem' }} className={props.className}>
                            <img src={props.imgSrc} className={props.classNameIcon} />
                        </Grid>
                        <p className={props.classNameText}>{props.title}</p>
                    </div>
                </Link>
            </Tooltip>
        )
    }

    const { Sider, Content } = Layout;

    return (
        <Layout>
            <Sider theme="light" width={225} style={{ height: '125vh' }} trigger={null}>
                {
                    location.pathname.includes('/command') &&
                    <div className='sidebar'>
                        <GoToPartner
                            title="Command"
                            imgSrc={'https://images-front-soukw.s3.amazonaws.com/home.svg'}
                            link={'https://console.command.kw.com/command'}
                            pageTitle={'Command'}
                        />
                        <GoToPartner
                            title="Connect"
                            imgSrc={'https://images-front-soukw.s3.amazonaws.com/iconConnect.svg'}
                            link={'https://console.command.kw.com/connect/learning'}
                            pageTitle={'Connect'}
                        />
                        <GoToPartner
                            title="Command MC"
                            imgSrc={'https://images-front-soukw.s3.amazonaws.com/iconCommandMc.svg'}
                            link={'https://console.command.kw.com/cloudmore'}
                            pageTitle={'Command MC'}
                        />
                        <GoToPartner
                            title="Frontdoor"
                            imgSrc={'https://images-front-soukw.s3.amazonaws.com/iconFrontdoor.svg'}
                            link={'https://console.command.kw.com/frontdoor'}
                            pageTitle={'Frontdoor'}
                        />
                    </div>
                }

                {
                    location.pathname.includes('/soukw') &&
                    <div className='sidebar'>
                        <GoToSouKw
                            classNameIcon='sidebar-icon-soukw'
                            classNameText='sidebar-text-soukw'
                            title='Home'
                            className='sidebarIcon'
                            selected={location.pathname === '/soukw' ? 'sidebar-box-selected' : 'sidebar-box'}
                            imgSrc='https://images-front-soukw.s3.amazonaws.com/home.svg'
                            pageTitle={'Home'}
                            route={'https://soukw.com.br/soukw'}
                        />
                        <GoToSouKw
                            classNameIcon='sidebar-icon-soukw'
                            classNameText='sidebar-text-soukw'
                            title='Treinamento'
                            className='sidebarIcon'
                            selected={location.pathname === '/soukw/home/training' ? 'sidebar-box-selected' : 'sidebar-box'}
                            imgSrc={'https://images-front-soukw.s3.amazonaws.com/iconConnect.svg'}
                            pageTitle={'Treinamentos'}
                            route={'/soukw/home/training'}
                        />
                        {
                            jwtUser?.canCreateAdd &&
                            <GoToSouKw
                                classNameIcon='sidebar-icon-soukw'
                                classNameText='sidebar-text-soukw'
                                title='Portais (XML)'
                                className='sidebarIcon'
                                selected={location.pathname === '/soukw/portals' ? 'sidebar-box-selected' : 'sidebar-box'}
                                imgSrc={'https://images-front-soukw.s3.amazonaws.com/iconPortals.svg'}
                                pageTitle={'Portais (XML)'}
                                route={'https://soukw.com.br/soukw/portals'}
                            />
                        }
                        {(user?.permissionLevel === 'ADMIN' || (jwtUser?.isMcAdmin || jwtUser?.isTeamAdmin)) && (
                            <GoToSouKw
                                classNameIcon='sidebar-icon-soukw'
                                classNameText='sidebar-text-soukw'
                                title='Gestão'
                                className='sidebarIcon'
                                selected={location.pathname === '/soukw/admin' ? 'sidebar-box-selected' : 'sidebar-box'}
                                imgSrc={'https://images-front-soukw.s3.amazonaws.com/usersIcon.svg'}
                                pageTitle={'Usuarios e Times'}
                                route={'https://soukw.com.br/soukw/users'}
                            />
                        )}
                        {
                            user?.permissionLevel === 'ADMIN' &&
                            <GoToSouKw
                                classNameIcon='sidebar-icon-soukw'
                                classNameText='sidebar-text-soukw'
                                title='Times & MC`s'
                                className='sidebarIcon'
                                selected={location.pathname === '/soukw/teams&mc' ? 'sidebar-box-selected' : 'sidebar-box'}
                                imgSrc={location.pathname === '/soukw/teams&mc' ? 'https://images-front-soukw.s3.amazonaws.com/taks.svg' : 'https://images-front-soukw.s3.amazonaws.com/taks.svg'}
                                pageTitle={'Times & MC`s'}
                                route={'https://soukw.com.br/soukw/teams&mc'}
                            />
                        }
                        <GoToSouKw
                            classNameIcon='sidebar-icon-soukw'
                            classNameText='sidebar-text-soukw'
                            title='Meu Perfil'
                            // className={location.pathname === '/soukw/myprofile' ? 'sidebar-box selected' : 'sidebar-box'}
                            className='sidebarIcon'
                            selected={location.pathname === '/soukw/myprofile' ? 'sidebar-box-selected' : 'sidebar-box'}
                            imgSrc={'https://images-front-soukw.s3.amazonaws.com/iconMyProfile.svg'}
                            pageTitle={'Meu Perfil'}
                            route={'https://soukw.com.br/soukw/myprofile'}
                        />
                        <GoToSouKw
                            classNameIcon='sidebar-icon-soukw'
                            classNameText='sidebar-text-soukw'
                            title='Listings Legados'
                            // className={location.pathname === '/soukw/listings' ? 'sidebar-box selected' : 'sidebar-box'}
                            className='sidebarIcon'
                            selected={location.pathname === '/soukw/listings' ? 'sidebar-box-selected' : 'sidebar-box'}
                            imgSrc={'https://images-front-soukw.s3.amazonaws.com/iconListings.svg'}
                            pageTitle={'Listings Legados'}
                            route={'https://soukw.com.br/soukw/listings'}
                        />
                        <GoToSouKw
                            classNameIcon='sidebar-icon-soukw'
                            classNameText='sidebar-text-soukw'
                            title='Listings Pre-Cadastro'
                            // className={location.pathname === '/soukw/prelistings' ? 'sidebar-box selected' : 'sidebar-box'}
                            className='sidebarIcon'
                            selected={location.pathname === '/soukw/prelistings' ? 'sidebar-box-selected' : 'sidebar-box'}
                            imgSrc={'https://images-front-soukw.s3.amazonaws.com/prelistingsIcon.png'}
                            pageTitle={'Listings Pre-Cadastro'}
                            route={'https://soukw.com.br/soukw/prelistings'}
                        />
                        {(user?.permissionLevel === 'ADMIN' || (jwtUser?.isMcAdmin || jwtUser?.isTeamAdmin)) && (
                            <GoToSouKw
                                classNameIcon='sidebar-icon-soukw'
                                classNameText='sidebar-text-soukw'
                                title='Planilha Growth'
                                className='sidebarIcon'
                                selected={location.pathname === '/soukw/dashboards' ? 'sidebar-box-selected' : 'sidebar-box'}
                                imgSrc={'https://images-front-soukw.s3.amazonaws.com/hovergrowth.svg'}
                                pageTitle={'Planilha Growth'}
                                route={'https://soukw.com.br/soukw/growth'}
                            />
                        )}

                        {
                            role ? user?.permissionLevel === "ADMIN" && (
                                <GoToSouKw
                                    classNameIcon='sidebar-icon-soukw'
                                    classNameText='sidebar-text-soukw'
                                    title='Transmittal'
                                    className='sidebarIcon'
                                    selected={location.pathname === '/soukw/transmittal' ? 'sidebar-box-selected' : 'sidebar-box'}
                                    imgSrc={'https://images-front-soukw.s3.amazonaws.com/transmittal.svg'}
                                    pageTitle={'Transmittal'}
                                    route={'/soukw/transmittal'}
                                />
                            ) : user?.permissionLevel === 'SUPER' && (
                                <GoToSouKw
                                    classNameIcon='sidebar-icon-soukw'
                                    classNameText='sidebar-text-soukw'
                                    title='Transmittal'
                                    className='sidebarIcon'
                                    selected={location.pathname === '/soukw/transmittal' ? 'sidebar-box-selected' : 'sidebar-box'}
                                    imgSrc={'https://images-front-soukw.s3.amazonaws.com/transmittal.svg'}
                                    pageTitle={'Transmittal'}
                                    route={'/soukw/transmittal'}
                                />
                            )
                        }
                    </div>
                }
                {
                    location.pathname.includes('/pipeimob') &&
                    <div className='sidebar'>
                        <GoToPartner
                            title="Estoque"
                            imgSrc={'https://images-front-soukw.s3.amazonaws.com/home.svg'}
                            link={`https://app.soukw.com.br/estoque/`}
                            pageTitle={'Estoque'}
                        />
                        <GoToPartner
                            title="Negócios"
                            imgSrc={'https://images-front-soukw.s3.amazonaws.com/negociospipe.png'}
                            link={`https://app.soukw.com.br/negocios/`}
                            pageTitle={'Negócios'}
                        />
                        <GoToPartner
                            title="Controles"
                            imgSrc={'https://images-front-soukw.s3.amazonaws.com/controlespipe.png'}
                            link={`https://app.soukw.com.br/solicitacoes/`}
                            pageTitle={'Controles'}
                        />
                        <GoToPartner
                            title="Equipe"
                            imgSrc={'https://images-front-soukw.s3.amazonaws.com/equipepipe.png'}
                            link={`https://app.soukw.com.br/usuarios/?abrir=usuarios`}
                            pageTitle={'Equipe'}
                        />
                        <GoToPartner
                            title="Financiero"
                            imgSrc={'https://images-front-soukw.s3.amazonaws.com/financeiropipe.png'}
                            link={`https://app.soukw.com.br/cobrancas_associados/`}
                            pageTitle={'Financeiro'}
                        />
                        <GoToPartner
                            title="Configurações"
                            imgSrc={'https://images-front-soukw.s3.amazonaws.com/confirguracoespipe.png'}
                            link={`https://app.soukw.com.br/configuracoes/`}
                            pageTitle={'Configurações'}
                        />
                        <GoToPartner
                            title="Integrações"
                            imgSrc={'https://images-front-soukw.s3.amazonaws.com/integrationintegration.png'}
                            link={`https://app.soukw.com.br/integracoes/`}
                            pageTitle={'Integrações'}
                        />
                    </div>
                }

                {
                    location.pathname.includes('/parcerias') &&
                    <div className='sidebar'>
                        <GoToSouKw
                            classNameIcon='sidebar-icon-parcerias'
                            classNameText='sidebar-text-parcerias'
                            // className={location.pathname == '/parcerias/courses' ? 'sidebar-box selected' : 'sidebar-box'}
                            className='sidebarIcon'
                            selected={location.pathname === '/parcerias/courses' ? 'sidebar-box-selected' : 'sidebar-box'}
                            imgSrc='https://images-front-soukw.s3.amazonaws.com/coursesPartner.svg'
                            pageTitle='Curso Técnico em Transações Imobiliárias'
                            route='/parcerias/courses'
                            title='Curso Técnico em Transações Imobiliárias'
                        />

                        <GoToSouKw
                            classNameIcon='sidebar-icon-parcerias'
                            classNameText='sidebar-text-parcerias'
                            className='sidebarIcon'
                            selected={location.pathname === '/parcerias/virtualdecorator' ? 'sidebar-box-selected' : 'sidebar-box'}
                            imgSrc='https://images-front-soukw.s3.amazonaws.com/decoratePartner.svg'
                            pageTitle='Decoração Virtual'
                            route='/parcerias/virtualdecorator'
                            title='Decoração Virtual'
                        />

                        <GoToSouKw
                            classNameIcon='sidebar-icon-parcerias'
                            classNameText='sidebar-text-parcerias'
                            className='sidebarIcon'
                            selected={location.pathname === '/parcerias/financial' ? 'sidebar-box-selected' : 'sidebar-box'}
                            imgSrc='https://images-front-soukw.s3.amazonaws.com/financialPartner.svg'
                            pageTitle='Financiamento Imobiliário'
                            route='/parcerias/financial'
                            title='Financiamento Imobiliário'
                        />

                        <GoToSouKw
                            classNameIcon='sidebar-icon-parcerias'
                            classNameText='sidebar-text-parcerias'
                            className='sidebarIcon'
                            selected={location.pathname === '/parcerias/leadsintegration' ? 'sidebar-box-selected' : 'sidebar-box'}
                            imgSrc='https://images-front-soukw.s3.amazonaws.com/integrationPartner.svg'
                            pageTitle='Integração de Leads'
                            route='/parcerias/leadsintegration'
                            title='Integração de Leads'
                        />

                        <GoToSouKw
                            classNameIcon='sidebar-icon-parcerias'
                            classNameText='sidebar-text-parcerias'
                            className='sidebarIcon'
                            selected={location.pathname === '/parcerias/im' ? 'sidebar-box-selected' : 'sidebar-box'}
                            imgSrc='https://images-front-soukw.s3.amazonaws.com/intelicensePartner.svg'
                            pageTitle='Inteligência de Mercado'
                            route='/parcerias/im'
                            title='Inteligência de Mercado'
                        />

                        <GoToSouKw
                            classNameIcon='sidebar-icon-parcerias'
                            classNameText='sidebar-text-parcerias'
                            className='sidebarIcon'
                            selected={location.pathname === '/parcerias/portals' ? 'sidebar-box-selected' : 'sidebar-box'}
                            imgSrc='https://images-front-soukw.s3.amazonaws.com/portalsPartner.svg'
                            pageTitle='Portais Imobiliários'
                            route='/parcerias/portals'
                            title='Portais Imobiliários'
                        />

                        <GoToSouKw
                            classNameIcon='sidebar-icon-parcerias'
                            classNameText='sidebar-text-parcerias'
                            className='sidebarIcon'
                            selected={location.pathname === '/parcerias/marketing' ? 'sidebar-box-selected' : 'sidebar-box'}
                            imgSrc='https://images-front-soukw.s3.amazonaws.com/marketingPartner.svg'
                            pageTitle='Marketing Imobiliaria'
                            route='/parcerias/marketing'
                            title='Marketing Imobiliaria'
                        />

                        <GoToSouKw
                            classNameIcon='sidebar-icon-parcerias'
                            classNameText='sidebar-text-parcerias'
                            className='sidebarIcon'
                            selected={location.pathname === '/parcerias/signaling' ? 'sidebar-box-selected' : 'sidebar-box'}
                            imgSrc='https://images-front-soukw.s3.amazonaws.com/sinalingPartner.svg'
                            pageTitle='Sinalização'
                            route='/parcerias/signaling'
                            title='Sinalização'
                        />

                        <GoToSouKw
                            classNameIcon='sidebar-icon-parcerias'
                            classNameText='sidebar-text-parcerias'
                            className='sidebarIcon'
                            selected={location.pathname === '/parcerias/managesoftware' ? 'sidebar-box-selected' : 'sidebar-box'}
                            imgSrc='https://images-front-soukw.s3.amazonaws.com/softwarePartner.svg'
                            pageTitle='Software de Gestão Imobiliária'
                            route='/parcerias/managesoftware'
                            title='Software de Gestão Imobiliária'
                        />

                        <GoToSouKw
                            classNameIcon='sidebar-icon-parcerias'
                            classNameText='sidebar-text-parcerias'
                            className='sidebarIcon'
                            selected={location.pathname === '/parcerias/legalsupport' ? 'sidebar-box-selected' : 'sidebar-box'}
                            imgSrc='https://images-front-soukw.s3.amazonaws.com/judgePartner.svg'
                            pageTitle='Suporte Jurídico'
                            route='/parcerias/legalsupport'
                            title='Suporte Jurídico'
                        />
                    </div>
                }


            </Sider>
            <Layout>
                <Content
                    style={{
                        margin: '1px 16px',
                        padding: 12,
                        height: '125hv'
                    }}
                >
                    {props.Children}
                </Content>
            </Layout>
        </Layout>
    );
};

export default Sidebar;